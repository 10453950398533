/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  html {
    font-size: 50%;
  }

  .heading-primary {
    font-size: 4.4rem;
  }

  .heading-secondary {
    font-size: 3.6rem;
    margin-bottom: 4.8rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }

  .grid {
    column-gap: 4.8rem;
    row-gap: 4.8rem;
  }

  .grid--2-cols,
  .grid--3-cols,
  .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .btn,
  .btn:link,
  .btn:visited {
    padding: 2rem 1.6rem;
  }

  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }

  .main-nav {
    background-color: rgba(255, 255, 255, 0.9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;

    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name='close-outline'] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name='menu-outline'] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 3.2rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
    max-width: fit-content;
  }

  .header {
    padding: 0 3.2rem;
  }

  .zalo-widget,
  .phone-widget {
    width: 5rem;
  }

  .widget-cantact-number {
    display: none !important;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem 0;
  }

  .hero {
    max-width: 120rem;
    grid-template-columns: 1fr;
    padding: 0 3.2rem;
    gap: 6.4rem;
  }

  .hero-text-box,
  .hero-img-box {
    text-align: center;
  }

  .hero-img-box {
    margin-top: 8rem;
  }

  .hero-img {
    width: 80%;
  }

  .logos img {
    height: 1.2rem;
  }

  .delivered-nests {
    height: 2.4rem;
    flex-direction: column;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
  }

  .why-img-box:nth-child(2) {
    grid-row: 1;
  }

  .why-img-box:nth-child(6) {
    grid-row: 5;
  }

  .why-img-box {
    transform: translateY(2.4rem);
  }

  .section-testimonials {
    grid-template-columns: 1fr;
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem;
  }

  .testimonials {
    grid-template-columns: 1fr;
  }

  .grid--footer {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 5rem !important;
    column-gap: 3rem !important;
  }

  .logo-col {
    grid-row: 5 / 6;
    grid-column: 1 / 2;
  }

  .address-col {
    grid-row: 1 / 2;
    grid-column: 1 / 1;
  }

  #map {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }
  .contacts {
    display: flex;
    flex-direction: column;
  }

  .company-col {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }

  .resource-col {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  .footer-contact {
    display: flex;
    flex-direction: column;
  }
}
