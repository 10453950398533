@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  html {
    font-size: 50%;
  }

  .heading-primary {
    font-size: 4.4rem;
  }

  .heading-secondary {
    margin-bottom: 4.8rem;
    font-size: 3.6rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }

  .grid {
    gap: 4.8rem;
  }

  .grid--2-cols, .grid--3-cols, .grid--4-cols {
    grid-template-columns: 1fr;
  }

  .btn, .btn:link, .btn:visited {
    padding: 2rem 1.6rem;
  }

  .btn-mobile-nav {
    z-index: 9999;
    display: block;
  }

  .main-nav {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    background-color: #ffffffe6;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    transition: all .5s ease-in;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 3.2rem;
  }

  .main-nav-link:link, .main-nav-link:visited {
    max-width: -moz-fit-content;
    max-width: fit-content;
    font-size: 3rem;
  }

  .header {
    padding: 0 3.2rem;
  }

  .zalo-widget, .phone-widget {
    width: 5rem;
  }

  .widget-cantact-number {
    display: none !important;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem;
  }

  .hero {
    grid-template-columns: 1fr;
    gap: 6.4rem;
    max-width: 120rem;
    padding: 0 3.2rem;
  }

  .hero-text-box, .hero-img-box {
    text-align: center;
  }

  .hero-img-box {
    margin-top: 8rem;
  }

  .hero-img {
    width: 80%;
  }

  .logos img {
    height: 1.2rem;
  }

  .delivered-nests {
    flex-direction: column;
    height: 2.4rem;
  }

  .gallery {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.2rem;
  }

  .why-img-box:nth-child(2) {
    grid-row: 1;
  }

  .why-img-box:nth-child(6) {
    grid-row: 5;
  }

  .why-img-box {
    transform: translateY(2.4rem);
  }

  .section-testimonials {
    grid-template-columns: 1fr;
  }

  .testimonials-container {
    padding: 9.6rem 3.2rem;
  }

  .testimonials {
    grid-template-columns: 1fr;
  }

  .grid--footer {
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr !important;
    gap: 5rem 3rem !important;
  }

  .logo-col {
    grid-area: 5 / 1 / 6 / 2;
  }

  .address-col {
    grid-area: 1 / 1 / 2 / 1;
  }

  #map {
    grid-area: 2 / 1 / 3 / 3;
  }

  .contacts {
    flex-direction: column;
    display: flex;
  }

  .company-col {
    grid-area: 4 / 1 / 5 / 2;
  }

  .resource-col {
    grid-area: 3 / 1 / 4 / 2;
  }

  .footer-contact {
    flex-direction: column;
    display: flex;
  }
}

/*# sourceMappingURL=index.83c90152.css.map */
